<template>
	<div class="main-content">
		<!--BreadCrumbs-->
		<ktv-breadcrumb
			:folder="$t('Loan', 'Loan')"
			:page="$t('Application Approval', 'Application Approval')"
			:title="$t('Application Approval List', 'Application Approval List')"
		/>
		<!--Tables Grid-->
		<tables-grid-approval />
	</div>
</template>
<script>
	import { KtvBreadcrumb } from "@/components"
	import TablesGridApproval from "./tables/TablesGridApproval"

	export default { 
		components: {
			KtvBreadcrumb,
			TablesGridApproval,
		}  
	}
</script>
