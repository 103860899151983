<template>
	<div>
		<ktv-table
			mode="remote"
			:fixed-header="true"
			:is-loading="loading"
			:columns="columns"
			:rows="rows"
			:total-rows="totalRecords"
			:rows-per-page="serverParams.limit"
			:search-enabled="false"
			:search-placeholder="$t('Searching by ID', 'Searching by ID')"
			@on-open-filter="openFilter()"
			@on-page-change="onPageChange"
			@on-sort-change="onSortChange"
			@on-per-page-change="onPerPageChange"
			@on-search="onSearchFilter"
		>
			<template #columns="{ props }">
				{{ $t(props.column.label, props.column.label) }}
			</template>
			<template #rows="{ props }">
				<span v-if="props.column.field === 'button'">
					<b-dropdown v-show="isRolesViewDetails" size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
						<template #button-content>
							<span><i class="nav-icon i-Arrow-Down-in-Circle" /></span>
						</template>
						<b-dropdown-item href="#" @click="onDetail(props.row.loanApplicationId, props.row.statusDesc)">
							<a v-b-tooltip.hover href="#" title="Edit">
								<i class="i-Pen-3 text-18 text-success mr-2" />
								{{ $t("Review Application Detail", "Review Application Detail") }}
							</a>
						</b-dropdown-item>
					</b-dropdown>
				</span>

				<span v-else-if="props.column.field === 'statusDesc'">
					<b-badge v-if="props.row.statusCode === 144" href="#" variant="danger m-2">{{ props.row.statusDesc }} </b-badge>
					<b-badge v-else-if="props.row.statusCode === 142" href="#" variant="dark m-2">{{ props.row.statusDesc }} </b-badge>
					<b-badge v-else-if="props.row.statusCode === 143" href="#" variant="success m-2">{{ props.row.statusDesc }} </b-badge>
				</span>
				<span v-else-if="props.column.field === 'totalLoanAmount'" style="float: right"> {{ props.row.totalLoanAmount | idr }} </span>
				<span v-else-if="props.column.field === 'totalLoanAmountApprove'" style="float: right"> {{ props.row.totalLoanAmountApprove | idr }}</span>
				<span v-else-if="props.column.field === 'statusNote'">
					{{ props.row.statusNote !== null && props.row.statusNote !== "" ? props.row.statusNote.substring(0, 10) + "..." : "" }}
				</span>
				<span v-else-if="props.column.field === 'tenor'" style="float: right"> {{ props.row.tenor }} </span>
			</template>
		</ktv-table>

		<!--modal filtering-->
		<ktv-modal v-model="modalsFilter" :title="$t('Filter', 'Filter')" @close="modalsFilter = false">
			<template #content>
				<b-form-group class="col-md-12 mb-2" :label="$t('Loan ID', 'Loan ID')">
					<b-form-input v-model="filter.loanApplicationId" type="text" style="background: #fff" />
				</b-form-group>
				<b-form-group class="col-md-12 mb-2" :label="$t('Name', 'Name')">
					<b-form-input v-model="filter.personName" type="text" style="background: #fff" />
				</b-form-group>
				<b-form-group class="col-md-12 mb-2" :label="$t('Category', 'Category')">
					<v-select v-model="filter.Category" :options="options.category" />
				</b-form-group>
				<b-form-group class="col-md-12 mb-2" :label="$t('Status', 'Status')">
					<v-select v-model="filter.Status" :options="options.status" />
				</b-form-group>
				<b-form-group class="col-md-12 mb-2" :label="$t('Date', 'Date')">
					<b-form-input v-model="filter.Date" type="date" />
				</b-form-group>

				<b-form-group class="col-md-12 mb-2">
					<div style="margin-top: 10px" />
					<b-button variant="primary" size="sm" class="float-right" @click="onAdvancedSearch"> Apply Filter </b-button>
					<b-button variant="light" size="sm" class="float-right" style="margin-right: 5px" @click="modalsFilter = false"> Close </b-button>
				</b-form-group>
			</template>
			<template #footer />
		</ktv-modal>
	</div>
</template>
<script>
	const column = [
		{
			label: "Action",
			field: "button",
			html: true,
			tdClass: "text-center",
			thClass: "text-center",
		},
		{
			label: "Loan ID",
			field: "loanApplicationId",
			thClass: "text-left",
			width: "150px",
		},
		{
			label: "Date",
			field: "createdDate",
			thClass: "text-left",
			width: "150px",
		},
		{
			label: "Name",
			field: "personName",
			thClass: "text-left",
			width: "250px",
		},
		{
			label: "Category",
			field: "value",
			thClass: "text-left",
			width: "150px",
		},
		{
			label: "Lender",
			field: "lenderName",
			thClass: "text-left",
			width: "250px",
		},
		{
			label: "Request Amount",
			field: "totalLoanAmount",
			thClass: "text-center",
			width: "200px",
		},
		{
			label: "Approved Amount",
			field: "totalLoanAmountApprove",
			thClass: "text-center",
			width: "200px",
		},
		{
			label: "Tenor",
			field: "tenor",
			thClass: "text-center",
			width: "150px",
		},
		{
			label: "Note",
			field: "statusNote",
			html: true,
			tdClass: "text-center",
			thClass: "text-center",
			width: "150px",
		},
		{
			label: "Status",
			field: "statusDesc",
			html: true,
			tdClass: "text-center",
			thClass: "text-center",
			width: "150px",
		},
	]

	import { KtvTable, KtvModal } from "@/components"
	import { mapActions, mapGetters } from "vuex"
	import getRolesWhile from "../../../../../libs/getRoles.js"

	export default {
		components: {
			KtvTable,
			KtvModal,
		},
		data() {
			return {
				loading: false,
				modalsFilter: false,
				filter: {
					loanApplicationId: "",
					personName: "",
					Lender: "",
					Category: "",
					Status: "",
					Date: "",
				},
				options: {
					category: [],
					status: [],
				},
				/* KtvTable property */
				columns: column,
				rows: [],
				totalRecords: 0,
				serverParams: {
					search: "",
					order: "namaField=loanApplicationId&orderBy=desc",
					page: 1,
					limit: 10,
					entityId: "",
					action: "",
				},
				sortKey: "",
				/* End KtvTable property */
				isRolesViewDetails: false,
			}
		},
		computed: {
			...mapGetters({
				loggedInUser: "AUTHDATA/loggedInUser",
				// TOTAL RECORDS GRID
				TotalRecords: "LOAN/getTotalRecords",
				// after commit from MASTER/ActionMasterCategory. so GET IT
				masterCategory: "MASTER/getMasterCategory",
				// after commit from MASTER/ActionMasterStatus. so GET IT
				masterStatus: "MASTER/getMasterStatus",
			}),
		},
		mounted() {
			this.loadItems()
			this.getMasterCategory()
			this.getMasterStatus()

			const isRolesViewDetails = this.getRolesWhile({
				modulename: "/loan",
				urlinfo: "/loan/applicationApproval",
				aksifungsi: "review_approval_detail",
				bool: true,
			}) // get roles
			this.isRolesViewDetails = isRolesViewDetails
		},
		created() {},
		methods: {
			...mapActions({
				getTablesGrid: "LOAN/ActionTablesGrid",
				getMasterCategory: "MASTER/ActionMasterCategory", // master data Category
				getMasterStatus: "MASTER/ActionMasterStatus", // master data Status
			}),
			onDetail(loanID, statusDesc) {
				localStorage.setItem("statusDesc", statusDesc)
				this.$router.push("/loan/approval-detail/" + loanID)
			},
			/**
			 * KtvTables Methods
			 */
			updateParams(newProps) {
				this.serverParams = Object.assign({}, this.serverParams, newProps)
			},
			// Untuk Paging Next - Previous hit
			onPageChange(params) {
				this.updateParams({ page: params.currentPage })
				this.loadItems()
			},
			// Untuk melakukan Jumlah dropdown Rowsperpages
			onPerPageChange(params) {
				this.updateParams({ limit: params.currentPerPage })
				this.loadItems()
			},
			// Untuk melakukan Sorting data
			onSortChange(params) {
				this.sortKey = params[0].field
				this.columns[params[0].field] = !this.columns[params[0].field]
				let oParams = {
					namaField: params[0].field,
					orderBy: this.columns[params[0].field] === false ? "asc" : "desc",
				}
				const NewParams = new URLSearchParams(oParams).toString()
				this.serverParams.order = NewParams
				this.loadItems()
			},
			// Untuk Load Data
			...getRolesWhile,
			loadItems() {
				const RolesGrid = this.getRolesWhile({
					modulename: "/loan",
					urlinfo: "/loan/applicationApproval",
					aksifungsi: "view_list",
				}) // get roles

				this.loading = true
				this.serverParams.entityId = this.loggedInUser.EntityOrganizationID

				this.serverParams.action = RolesGrid
				this.getTablesGrid(this.serverParams).then((data) => {
					this.loading = false
					this.totalRecords = this.TotalRecords
					if (data) this.rows = data
				})
			},
			onAdvancedSearch() {
				this.modalsFilter = false
				const request = {
					loanApplicationId: this.filter.loanApplicationId === null || this.filter.loanApplicationId === "" ? "" : this.filter.loanApplicationId,
					personName: this.filter.personName === null || this.filter.personName === "" ? "" : this.filter.personName,
					lender: this.filter.Lender === null || this.filter.Lender === "" ? "" : this.filter.Lender.label,
					category: this.filter.Category === null || this.filter.Category === "" ? "" : this.filter.Category.label,
					status: this.filter.Status === null || this.filter.Status === "" ? "" : this.filter.Status.label,
					date: this.filter.Date === null ? "" : this.filter.Date,
				}
				Object.keys(request).forEach((k) => !request[k] && request[k] !== undefined && delete request[k])
				const NewParams = new URLSearchParams(request).toString()
				this.serverParams.search = decodeURIComponent(NewParams.replace(/\+/g, "%20"))
				this.loadItems()
			},
			openFilter() {
				this.modalsFilter = true

				// menambahkan data option category ke select
				let category = []
				this.masterCategory.forEach((items) => {
					const obj = {
						id: items.entityListOfValueId,
						label: items.value,
					}
					category.push(obj)
				})
				this.options.category = category
				// menambahkan data option status ke select
				let status = []
				this.masterStatus.forEach((items) => {
					const obj = {
						id: items.entityListOfValueId,
						label: items.value,
					}
					status.push(obj)
				})
				this.options.status = status
			},
			onSearchFilter(params) {
				const request = {
					loanApplicationId: params === null || params === "" ? "" : params,
				}
				Object.keys(request).forEach((k) => !request[k] && request[k] !== undefined && delete request[k])
				const NewParams = new URLSearchParams(request).toString()
				this.serverParams.search = decodeURIComponent(NewParams.replace(/\+/g, "%20"))
				this.loadItems()
			},
			/**
			 * End KtvTables Methods
			 */
		},
	}
</script>
